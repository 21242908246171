/* eslint-disable eqeqeq */
import styled from 'styled-components'
import SearchBox from '../SearchBox'

export const StyledSearchBox = styled(SearchBox).attrs(() => ({
  color: 'light',
}))`
  ${({ showSearch }) => showSearch && `
    margin-left: 25%;
  `}
`
