import React, { useContext, useMemo } from 'react'
import { Link } from 'gatsby'
import { Grid, Row } from '@zendeskgarden/react-grid'

import { ensureArray } from '@agnostack/lib-core'

import { GlobalState } from '../../../util'
import { NavigationLink } from '../../../styles/core'
import {
  NavigationWrapper,
  HeaderWrapper,
  StyledHeader,
  LinkWrapper,
  LogoWrapper,
  LogoImage,
  TextLinks,
} from './Header.style'

const Header = ({ items, children }) => {
  const { activeFormat, showSearch } = useContext(GlobalState)

  const { links, Search, CTAs } = useMemo(() => (
    ensureArray(items).reduce((
      { links: _links, Search: _Search, CTAs: _CTAs },
      { exclude, text, link, SEARCH: __Search, CTA }
    ) => {
      const isExcluded = ensureArray(exclude).includes('desktop')
      return {
        links: [
          ..._links,
          ...(text && link && !isExcluded) ? [{ text, link }] : []
        ],
        CTAs: [
          ..._CTAs,
          ...(CTA && !isExcluded) ? [CTA] : []
        ],
        Search: !isExcluded
          ? __Search || _Search
          : _Search,
      }
    }, { links: [], CTAs: [] })
  ), [items])

  return (
    <StyledHeader>
      <HeaderWrapper>
        <Grid>
          <Row>
            <LogoWrapper>
              <Link to="/">
                <LogoImage>
                  {children}
                </LogoImage>
              </Link>
            </LogoWrapper>
            <NavigationWrapper>
              <LinkWrapper showSearch={showSearch}>
                <TextLinks>
                  {!showSearch && ensureArray(links).map(({ text, link }, index) => (
                    <NavigationLink key={`header-${index}`} to={link}>
                      {text}
                    </NavigationLink>
                  ))}
                  {Search && (
                    <Search key="header-search" />
                  )}
                </TextLinks>
                <div>
                  {ensureArray(CTAs).map((CTA, index) => (
                    <CTA key={`header-${index}`} format={activeFormat} />
                  ))}
                </div>
              </LinkWrapper>
            </NavigationWrapper>
          </Row>
        </Grid>
      </HeaderWrapper>
    </StyledHeader>
  )
}

export default Header
