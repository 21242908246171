import { ensureNumericNegatable, DEFAULT_LOCALE } from '@agnostack/lib-core'

export const getNumericFormatter = ({
  locale = DEFAULT_LOCALE,
  formatWithSymbol,
  style,
  code,
  precision,
  ...options
} = {}) => (
  new Intl.NumberFormat(locale, code ? {
    currency: code,
    // eslint-disable-next-line eqeqeq
    ...(precision != undefined) && {
      maximumFractionDigits: precision,
      minimumFractionDigits: precision,
    },
    ...options,
    ...(formatWithSymbol || style) && { style: style || 'currency' },
  } : options)
)

export const formatCommas = (value, locale) => (
  getNumericFormatter({ locale }).format(ensureNumericNegatable(value))
)
