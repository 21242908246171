/* eslint-disable no-restricted-syntax */
import currency from 'currency.js'

import { ensureObject } from '@agnostack/lib-core'
import { getNumericFormatter } from './formatting'

export const getCurrencyFormatter = ({
  code = 'USD',
  ...options
} = {}) => (
  getNumericFormatter({ code, ...options })
)

export const numeric = (value, options) => {
  const formatter = getCurrencyFormatter(options)

  const trim = (amount) => {
    let number = ''

    for (const part of formatter.formatToParts(amount)) {
      const { type, value: _value } = part
      if (type === 'decimal') {
        break
      }

      number += _value
    }

    return number
  }

  return {
    ...currency(value, options),
    format: () => formatter.format(value),
    formatToParts: () => formatter.formatToParts(value),
    trim: () => trim(value),
  }
}

// TODO: explore calling formatPrice with '$5'
// eslint-disable-next-line default-param-last
export const formatPrice = (price = 0, _currencyCode, data) => {
  const currencyCode = _currencyCode || 'USD'
  const code = currencyCode.toUpperCase()
  const { format, ...options } = ensureObject(data)

  const intVal = numeric(price).intValue
  const precise = intVal / 100

  const amount = Math.round(precise)
  const formattedAmount = (format === 'absolute') ? Math.abs(amount) : amount

  return {
    currency: code,
    amount,
    precise,
    rounded: `${numeric(formattedAmount / 100, {
      code,
      precision: 0,
      formatWithSymbol: true,
      ...options,
    }).format()}`,
    formatted: `${numeric(formattedAmount / 100, {
      code,
      formatWithSymbol: true,
      ...options,
    }).format()}`,
  }
}
