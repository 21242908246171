import { useMemo } from 'react'
import { useFlexSearch } from 'react-use-flexsearch'

import { isString } from '@agnostack/lib-utils-js'
import { stringNotEmpty, lowercase } from '@agnostack/lib-core'

const sanitizeSearchQuery = (searchQuery) => {
  if (!isString(searchQuery)) {
    return ''
  }

  let sanitizedQuery = searchQuery.trim()

  sanitizedQuery = sanitizedQuery.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')

  sanitizedQuery = sanitizedQuery
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

  sanitizedQuery = sanitizedQuery.slice(0, 100)

  return sanitizedQuery
}

export const useSearch = (searchQuery, { index, store } = {}) => {
  const sanitizedQuery = sanitizeSearchQuery(searchQuery)
  const _results = useFlexSearch(sanitizedQuery, index, store)

  const isPrioritized = (term) => (
    stringNotEmpty(term) && lowercase(term).includes(lowercase(searchQuery))
  )

  const { prioritizedResults, results } = useMemo(() => {
    if (!sanitizedQuery) {
      return {
        results: _results,
        prioritizedResults: [],
      }
    }

    return _results?.reduce((previousResults, result) => {
      const { search } = result
      if (!isPrioritized(search)) {
        return {
          ...previousResults,
          results: [
            ...previousResults.results,
            result
          ],
        }
      }

      return {
        ...previousResults,
        prioritizedResults: [
          ...previousResults.prioritizedResults,
          result
        ],
      }
    }, { prioritizedResults: [], results: [] })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_results, sanitizedQuery])

  return { prioritizedResults, results }
}
