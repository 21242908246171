import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
  lazy,
  Suspense,
} from 'react'
import { window, exists } from 'browser-monads-ts'
import { withTheme } from 'styled-components'
import { navigate } from 'gatsby'

// TODO: explore importing Dropdown/Menu from @agnostack/components-atoms
import {
  Menu,
  Trigger,
  Dropdown,
  MediaBody,
  MediaFigure,
} from '@zendeskgarden/react-dropdowns'
import { Button } from '@zendeskgarden/react-buttons'

import { ListingIcons } from '@agnostack/components-icons'

import { useDimensions } from '../../../hooks'
import { ScheduleButton, SearchNav } from '../../atoms'
import { GlobalDispatch, GLOBAL_ACTIONS, GLOBAL_PARAMS, LISTINGS } from '../../../util'
import MobileHeader from '../MobileHeader'
import Header from '../Header'
import Footer from '../Footer'
import {
  NavigationContentWrapper,
  SplitWrapper,
  InstallButton,
  AppsButton,
  AppItem,
} from './Navigation.style'

const LazyNavigationWrapper = lazy(
  () => import('./Navigation.style').then((module) => ({ default: module.NavigationWrapper }))
)

const NavigationWrapper = ({ fallback = <div />, ...props }) => {
  if (!exists(window)) {
    return null
  }

  return (
    <Suspense fallback={fallback}>
      <LazyNavigationWrapper {...props} />
    </Suspense>
  )
}

const LISTING_ENTRIES = Object.entries(LISTINGS)
const INSTALL_NOW = 'Install Now'

const AgnoStackWordmark = () => (
  <img
    src="/images/agnoStack-wordmark-inverse.png"
    alt="agnoStack, Inc."
  />
)

const AgnoStackIcon = () => (
  <img
    src="/images/agnoStack-icon-inverse.png"
    alt="agnoStack, Inc."
  />
)

const SecondaryButton = (renderProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useContext(GlobalDispatch)

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  const handleNavigate = (listing = 'agnostack') => {
    const { url } = LISTINGS[listing]
    window.open(url, '_blank')
    dispatch({
      type: GLOBAL_ACTIONS.ACTIVE_LISTING,
      payload: { [GLOBAL_PARAMS.LISTING]: listing },
    })
    navigate('/faqs/setup-onboarding/install-configure-your-app')
  }

  if (LISTING_ENTRIES.length < 2) {
    return (
      <Button
        {...renderProps}
        onClick={() => handleNavigate()}
      >
        {INSTALL_NOW}
      </Button>
    )
  }

  return (
    <SplitWrapper onClick={handleClick}>
      {/* TODO fix format mapping for medium */}
      <InstallButton {...renderProps} format="dark">
        {INSTALL_NOW}
      </InstallButton>
      <Dropdown
        isOpen={isOpen}
        onSelect={handleNavigate}
      >
        <Trigger>
          {/* TODO fix format mapping for medium */}
          <AppsButton
            {...renderProps}
            format="dark"
            isRotated={isOpen}
          />
        </Trigger>
        <Menu placement="bottom-end">
          {LISTING_ENTRIES.map(([listing, { title }]) => {
            const Icon = ListingIcons[listing]
            return !Icon ? null : (
              <AppItem key={listing} value={listing}>
                <MediaFigure style={{ width: 24, height: 24 }}>
                  <Icon />
                </MediaFigure>
                <MediaBody>
                  {title}
                </MediaBody>
              </AppItem>
            )
          })}
        </Menu>
      </Dropdown>
    </SplitWrapper>
  )
}

const NAVIGATION_ITEMS = [
  {
    text: 'Features',
    link: '/#features',
  },
  {
    text: 'Integrations',
    link: '/#integrations',
  },
  {
    text: 'Pricing',
    link: '/pricing',
  },
  {
    text: 'Updates',
    link: '/release-notes',
  },
  {
    text: 'FAQs',
    link: '/faqs',
  },
  {
    text: 'News',
    link: '/press-releases',
  },
  {
    text: 'Search',
    link: '/search',
    exclude: 'desktop',
  },
  {
    SEARCH: SearchNav,
    exclude: 'footer',
  },
  {
    CTA: ScheduleButton,
    exclude: 'footer',
  },
  {
    CTA: SecondaryButton,
    exclude: ['footer', 'mobile'],
  }
]

const Navigation = ({ bare, children, theme }) => {
  const [enableSticky, setEnableSticky] = useState(false)
  const navRef = useRef()
  const [{ width }] = useDimensions(window)
  const { breakpoints } = theme || {}
  const { md: breakpointPx = '', numeric } = breakpoints || {}
  const breakpoint = numeric(breakpointPx)

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if ((window?.addEventListener != undefined) && navRef?.current && (width > breakpoint)) {
      const scrollCallBack = window.addEventListener('scroll', () => {
        if (navRef && navRef.current) {
          setEnableSticky(window.pageYOffset > 2 * navRef.current.scrollHeight)
        }
      })
      return () => {
        window.removeEventListener('scroll', scrollCallBack)
      }
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navRef])

  const { Logo, navigationItems } = useMemo(() => {
    if (bare) {
      return {
        Logo: AgnoStackIcon,
      }
    }

    return {
      Logo: AgnoStackWordmark,
      navigationItems: NAVIGATION_ITEMS,
    }
  }, [bare])

  return (
    <>
      <NavigationWrapper
        id="header_navigation"
        header
        component={<header ref={navRef} />}
        sticky={!bare ? enableSticky : false}
      >
        <NavigationContentWrapper>
          <Header items={navigationItems}>
            <Logo />
          </Header>
          <MobileHeader items={navigationItems}>
            <Logo />
          </MobileHeader>
        </NavigationContentWrapper>
      </NavigationWrapper>
      {children}
      {!bare && (
        <NavigationWrapper component={<footer />}>
          <Footer items={NAVIGATION_ITEMS} />
        </NavigationWrapper>
      )}
    </>
  )
}

export default withTheme(Navigation)
