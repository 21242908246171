/* eslint-disable no-underscore-dangle */
import React, { useContext, useMemo } from 'react'
import classNames from 'classnames'
import { window, exists } from 'browser-monads-ts'

import { splitCommas, stringNotEmpty } from '@agnostack/lib-core'

import { GlobalState, GLOBAL_PARAMS } from '../../../util'

// eslint-disable-next-line prefer-destructuring
const GATSBY_SITE_FLAGS = process.env.GATSBY_SITE_FLAGS // TODO: add more complex flaggable component
const flags = splitCommas(GATSBY_SITE_FLAGS)

const ConditionalContent = ({ children, className, ...renderProps }) => {
  const { [GLOBAL_PARAMS.LISTING]: listingContext } = useContext(GlobalState)

  const { conditionalListing, showChildren, dataProps } = useMemo(() => {
    const { flag, listing } = renderProps
    const _dataProps = Object.entries(renderProps).reduce((__dataProps, [key, value]) => ({
      ...__dataProps,
      ...typeof value === 'string' && {
        [`data-${key}`]: value,
      },
    }), {})

    return {
      showChildren: (listingContext && (listingContext === listing)) || (stringNotEmpty(flag) && flags.includes(flag)),
      conditionalListing: listing,
      dataProps: _dataProps,
    }
  }, [listingContext, renderProps])

  // NOTE: ensures browser only
  if (!exists(window) || !showChildren) {
    return null
  }

  return (
    <div data-type="conditional-content" data-listing={conditionalListing} className={classNames('conditionalContent', className)} {...dataProps}>
      {children}
    </div>
  )
}

export default ConditionalContent
