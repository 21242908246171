import React, { useContext } from 'react'

import { GlobalDispatch, GlobalState, GLOBAL_ACTIONS } from '../../../util'
import { StyledSearchBox } from './SearchNav.style'

const SearchNav = (renderProps) => {
  const { showSearch } = useContext(GlobalState)
  const dispatch = useContext(GlobalDispatch)

  const onShowSearch = (value) => {
    dispatch({
      type: GLOBAL_ACTIONS.SET,
      payload: { showSearch: value },
    })
  }

  return (
    <StyledSearchBox
      showSearch={showSearch}
      onShowSearch={onShowSearch}
      {...renderProps}
    />
  )
}

export default SearchNav
